class Client {
    constructor(id, Last, First, Dbo, License, Address, Apt, City, Zip, Phone1, Phone2, Year, Make, Model, Color, Vin, Lpn, Price, Soldat, Datesold, Warranty, Warrantytext, 
        Refname1, Refname2, Refname3, Refname4, Refphone1, Refphone2, Refphone3, Refphone4, Down, Datesign, Months, Rate, Balance, Monthlypay, Active) {
    this.id = id;
    this.Last =  Last;
    this.First =  First;
    this.Dbo =  Dbo;
    this.License =  License;
    this.Address =  Address;
    this.Apt =  Apt;
    this.City =  City;
    this.Zip =  Zip;
    this.Phone1 =  Phone1;
    this.Phone2 =  Phone2;
    this.Year =  Year;
    this.Make =  Make;
    this.Model =  Model;
    this.Color =  Color;
    this.Vin =  Vin;
    this.Lpn =  Lpn;
    this.Price =  Price;
    this.Soldat =  Soldat;
    this.Datesold =  Datesold;
    this.Warranty =  Warranty;
    this.Warrantytext =  Warrantytext;
    this.Refname1 =  Refname1;
    this.Refname2 =  Refname2;
    this.Refname3 =  Refname3;
    this.Refname4 =  Refname4;
    this.Refphone1 =  Refphone1;
    this.Refphone2 =  Refphone2;
    this.Refphone3 =  Refphone3;
    this.Refphone4 =  Refphone4;
    this.Down =  Down;
    this.Datesign =  Datesign;
    this.Months =  Months;
    this.Rate =  Rate;
    this.Balance =  Balance;
    this.Monthlypay =  Monthlypay;
    this.Active = Active
  
  }
    }
    
    export default Client;
