import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner  } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Link } from 'react-router-dom';
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
import dbMakes from "../db/dbMakes.json"
import dbModels from "../db/dbModels.json"
// import Getidinvoice from '../components/Getidinvoice';
// import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
// import logo from '../assets/image/logo.png'
 
const InvoiceLast = () => { 


  const user = useSelector(selectUser)
  const ultimo = useSelector(state => state.invoices.invoices)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const randon = Math.floor(Math.random() * (999999  - 202111 + 1) + 202111) 

  //Get profile picture from firebase
  const getProfileImage = () => {
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo.png")
    .getDownloadURL()
    .then(imgUrl => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])


  

 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time

  const [idinvoice, setidinvoice] = useState(randon)
  const [name,      setname] = useState('')
  const [phone,     setphone] = useState('')
  const [date,      setdate] = useState('')

  const [brand,      setbrand] = useState('')
  const [adate,      setadate] = useState('')
  const [ddate,      setddate] = useState('')
  const [license,   setlicense] = useState('')
  const [make,      setmake] = useState('')
  const [mileage,      setmileage] = useState('')
  const [model,      setmodel] = useState('')
  const [bin,      setbin] = useState('')
  const [year,      setyear] = useState('')


  const [balance,   setbalance] = useState('')
  const [payment,   setpayment] = useState('')
  const [recommend, setrecommend] = useState('')
     
      const [pri1, setpri1] = useState(0)
      const [qua1, setqua1] = useState(0)
      const [des1, setdes1] = useState('')
      const [lab1, setlab1] = useState(0)
      const [war1, setwar1] = useState('')
      const [pri2, setpri2] = useState(0)
      const [qua2, setqua2] = useState(0)
      const [des2, setdes2] = useState('')
      const [lab2, setlab2] = useState(0)
      const [war2, setwar2] = useState('')
      const [pri3, setpri3] = useState(0)
      const [qua3, setqua3] = useState(0)
      const [des3, setdes3] = useState('')
      const [lab3, setlab3] = useState(0)
      const [war3, setwar3] = useState('')
      const [pri4, setpri4] = useState(0)
      const [qua4, setqua4] = useState(0)
      const [des4, setdes4] = useState('')
      const [lab4, setlab4] = useState(0)
      const [war4, setwar4] = useState('')
      const [pri5, setpri5] = useState(0)
      const [qua5, setqua5] = useState(0)
      const [des5, setdes5] = useState('')
      const [lab5, setlab5] = useState(0)
      const [war5, setwar5] = useState('')

      const [pri6, setpri6] = useState(0)
      const [qua6, setqua6] = useState(0)
      const [des6, setdes6] = useState('')
      const [lab6, setlab6] = useState(0)
      const [war6, setwar6] = useState('')
      
      const [pri7, setpri7] = useState(0)
      const [qua7, setqua7] = useState(0)
      const [des7, setdes7] = useState('')
      const [lab7, setlab7] = useState(0)
      const [war7, setwar7] = useState('')

      const [pri8, setpri8] = useState(0)
      const [qua8, setqua8] = useState(0)
      const [des8, setdes8] = useState('')
      const [lab8, setlab8] = useState(0)
      const [war8, setwar8] = useState('')

      const [pri9, setpri9] = useState(0)
      const [qua9, setqua9] = useState(0)
      const [des9, setdes9] = useState('')
      const [lab9, setlab9] = useState(0)
      const [war9, setwar9] = useState('')


      const [pri10, setpri10] = useState(0)
      const [qua10, setqua10] = useState(0)
      const [des10, setdes10] = useState('')
      const [lab10, setlab10] = useState(0)
      const [war10, setwar10] = useState('')


      const [amo , setamo]  = useState(0)
      
     function myChangeHandleridinvoice(event) { setidinvoice(event.target.value)}
     function myChangeHandlername(event) { setname(event.target.value)}
     function myChangeHandlerphone(event) { setphone(event.target.value)}     
     function myChangeHandlerdate(event) { setdate(event.target.value)}

     function myChangeHandlerbrand  (event) { setbrand(event.target.value)}
     function myChangeHandleradate  (event) { setadate(event.target.value)}
     function myChangeHandlerddate  (event) { setddate(event.target.value)}
     function myChangeHandlerlicense(event) { setlicense(event.target.value)}
     function myChangeHandlermake   (event) { setmake(event.target.value)}
     function myChangeHandlermileage(event) { setmileage(event.target.value)}
     function myChangeHandlermodel  (event) { setmodel(event.target.value)}
     function myChangeHandlerbin    (event) { setbin(event.target.value)}
     function myChangeHandleryear   (event) { setyear(event.target.value)}




     function myChangeHandlerbalance() { 
       
      setbalance(
        
          ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ) + 
            ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
            ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
            ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
            ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
            ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )) - (amo ? parseInt(amo) : 0)).toFixed(2)
      )
    
    }
     function myChangeHandlerpayment(event) { setpayment(event.target.value)}
     function myChangeHandlerrecommend(event) { setrecommend(event.target.value)}

     function myChangeHandlerpri1(event) { setpri1(event.target.value)}
     function myChangeHandlerqua1(event) { setqua1(event.target.value)}     
     function myChangeHandlerdes1(event) { setdes1(event.target.value)}      
     function myChangeHandlerlab1(event) { setlab1(event.target.value)}      
     function myChangeHandlerwar1(event) { setwar1(event.target.value)} 

     function myChangeHandlerpri2(event) { setpri2(event.target.value)}
     function myChangeHandlerqua2(event) { setqua2(event.target.value)}
     function myChangeHandlerdes2(event) { setdes2(event.target.value)}  
     function myChangeHandlerlab2(event) { setlab2(event.target.value)}      
     function myChangeHandlerwar2(event) { setwar2(event.target.value)} 

     function myChangeHandlerpri3(event) { setpri3(event.target.value)}
     function myChangeHandlerqua3(event) { setqua3(event.target.value)}
     function myChangeHandlerdes3(event) { setdes3(event.target.value)} 
     function myChangeHandlerlab3(event) { setlab3(event.target.value)}      
     function myChangeHandlerwar3(event) { setwar3(event.target.value)} 

     function myChangeHandlerpri4(event) { setpri4(event.target.value)}
     function myChangeHandlerqua4(event) { setqua4(event.target.value)}
     function myChangeHandlerdes4(event) { setdes4(event.target.value)} 
     function myChangeHandlerlab4(event) { setlab4(event.target.value)}      
     function myChangeHandlerwar4(event) { setwar4(event.target.value)} 

     function myChangeHandlerpri5(event) { setpri5(event.target.value)}
     function myChangeHandlerqua5(event) { setqua5(event.target.value)}
     function myChangeHandlerdes5(event) { setdes5(event.target.value)} 
     function myChangeHandlerlab5(event) { setlab5(event.target.value)}      
     function myChangeHandlerwar5(event) { setwar5(event.target.value)} 
     
     function myChangeHandlerpri6(event) { setpri6(event.target.value)}
     function myChangeHandlerqua6(event) { setqua6(event.target.value)}
     function myChangeHandlerdes6(event) { setdes6(event.target.value)}  
     function myChangeHandlerlab6(event) { setlab6(event.target.value)}      
     function myChangeHandlerwar6(event) { setwar6(event.target.value)} 

     function myChangeHandlerpri7(event) { setpri7(event.target.value)}
     function myChangeHandlerqua7(event) { setqua7(event.target.value)}
     function myChangeHandlerdes7(event) { setdes7(event.target.value)}
     function myChangeHandlerlab7(event) { setlab7(event.target.value)}
     function myChangeHandlerwar7(event) { setwar7(event.target.value)}

    function myChangeHandlerpri8(event) { setpri8(event.target.value)}
    function myChangeHandlerqua8(event) { setqua8(event.target.value)}
    function myChangeHandlerdes8(event) { setdes8(event.target.value)}
    function myChangeHandlerlab8(event) { setlab8(event.target.value)}
    function myChangeHandlerwar8(event) { setwar8(event.target.value)}

    function myChangeHandlerpri9(event) { setpri9(event.target.value)}
    function myChangeHandlerqua9(event) { setqua9(event.target.value)}
    function myChangeHandlerdes9(event) { setdes9(event.target.value)}
    function myChangeHandlerlab9(event) { setlab9(event.target.value)}
    function myChangeHandlerwar9(event) { setwar9(event.target.value)}

    function myChangeHandlerpri10(event) { setpri10(event.target.value)}
    function myChangeHandlerqua10(event) { setqua10(event.target.value)}
    function myChangeHandlerdes10(event) { setdes10(event.target.value)}
    function myChangeHandlerlab10(event) { setlab10(event.target.value)}
    function myChangeHandlerwar10(event) { setwar10(event.target.value)}



     function myChangeHandleramo(event)  { setamo(event.target.value)}


     function print(){
         window.print()
       }


       const authentication = () => {          
 
       const data = {
        "Idinvoice": idinvoice,
        "Name":      name,
        "Phone":     phone,
        "Date":      date,
        "Brand": brand,  
        "Adate": adate,  
        "Ddate": ddate,  
        "License": license,
        "Make": make,   
        "Mileage": mileage,
        "Model": model,  
        "Bin": bin,    
        "Year": year,   
        "Amount": amo,   
        
        "Balance":   ((((pri1 ? parseInt(pri1) * parseInt(qua1) : 0) ) + 
            ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
            ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
            ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
            ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
            ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) ) + 
            ((pri7 ?  parseInt(pri7) * parseInt(qua7) : 0) )+ 
            ((pri8 ?  parseInt(pri8) * parseInt(qua8) : 0) )+ 
            ((pri9 ?  parseInt(pri9) * parseInt(qua9) : 0) )+ 
            ((pri10 ?  parseInt(pri10) * parseInt(qua10) : 0) )) - (amo ? parseInt(amo) : 0)),
        "Payment":   payment,
        "Recommend": recommend,
          "Lines_details": [         
              {
                  "Des": des1,
                  "Pri": pri1,
                  "Qua": qua1,
                  "Lab": lab1
              },
              { 
                  "Des": des2,
                  "Pri": pri2,
                  "Qua": qua2,
                  "Lab": lab2
              },
              {
                "Des": des3,
                "Pri": pri3,
                "Qua": qua3,
                "Lab": lab3
              },
              {
                "Des": des4,
                "Pri": pri4,
                "Qua": qua4,
                "Lab": lab4
              },
              {
                "Des": des5,
                "Pri": pri5,
                "Qua": qua5,
                "Lab": lab5
              },
              {
                "Des": des6,
                "Pri": pri6,
                "Qua": qua6,
                "Lab": lab6
              },
              {
                "Des": des7,
                "Pri": pri7,
                "Qua": qua7,
                "Lab": lab7
              },
              {
                "Des": des8,
                "Pri": pri8,
                "Qua": qua8,
                "Lab": lab8
              },
              {
                "Des": des9,
                "Pri": pri9,
                "Qua": qua9,
                "Lab": lab9
              },
              {
                "Des": des10,
                "Pri": pri10,
                "Qua": qua10,
                "Lab": lab10
              }
          ]
      }       

      Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATE_INVOICE}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Invoice has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )


      }


     
    return (
        <>
        <Container style={{marginTop:'40px'}}>   
            <form >
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 {
                   url != null ?
                   <img               
                  style={{width: '230px', height:100}}
                  src={url}
                  alt='..'  /> :
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                 }
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Invoice</h3>
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-3' variant="middle" />
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>
                <Col md={4}>
                  <h5 style={{marginTop:12}}>Bill To:</h5>
                </Col>
                <Col >
                <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" onChange={myChangeHandlername} />
                </Col>
              </Row>
              <Row>
                <Col  md={4}>
                  <h5 style={{marginTop:12}}>Phone:</h5>
                </Col>
                <Col>
                <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" onChange={myChangeHandlerphone} />
                </Col>
              </Row>
              {/* <h4>Bill To:</h4>                
              <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
              <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
            </Col>
            <Col md={{ span: 4, offset: 4 }}> 
                <Row>
                    <Col md={6}>
                    <h5>Invoice No:</h5>
                    </Col>
                    <Col md={6}>                      
                       {/* <Getidinvoice  /> */}
                    {/* <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard" value={randon} onChange={() => setidinvoice(randon)} /> */}
                    <h5>{idinvoice}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                    <h5>Date:</h5>
                    </Col>
                    <Col md={5}>
                    <TextField  id="Date" name="Date" type='date'  variant="standard" onChange={myChangeHandlerdate} />
                    </Col>
                </Row>
            </Col>
            
        </Row>
        <h4>Car Information</h4>
        {/* <Divider variant="middle" /> */}
        <Row className='mb-3'>            
            <Col md={4}>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Car Name:</h5>                            
                    </Col>
                    <Col md={7}>
                    {/* <Autocomplete
                      id="idBrand"
                      options={dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Brand" name="Brand" type='text' label=" "  variant="standard" onSelect={myChangeHandlerbrand} />}
                    />  */}
                    <TextField id="Brand" name="Brand" type='text' label=" "  variant="standard" onChange={myChangeHandlerbrand} />
                    </Col>
                </Row> 
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Year:</h5>                            
                    </Col>
                    <Col md={7}>
                    {/* <Autocomplete
                      id="idYear"
                      options={dbdata.year}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label=" " variant="standard" onSelect={myChangeHandleryear} />}
                    />  */}
                    <TextField id="Year" name="Year" type='text' label=" " variant="standard" onChang={myChangeHandleryear} />
                    </Col> 
                </Row>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Mileage:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField style={{ width: 160 }} id="Mileage" name="Mileage" type='number' label=" " variant="standard" onChange={myChangeHandlermileage} />
                    </Col> 
                </Row>                                              
            </Col>  
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Make:</h5>                            
                    </Col>
                    <Col md={8}>
                    {/* <Autocomplete
                      id="idMake"
                      options={ dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 200 }}
                      renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label=" " variant="standard" onSelect={myChangeHandlermake} />}
                    />  */}
                    <TextField  id="Make" name="Make" type='text' label=" " variant="standard" onChange={myChangeHandlermake} />
                    </Col>
                </Row>                 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5>LPN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 200 }} id="License" name="License" type='text' label="License Plate Number" variant="standard" onChange={myChangeHandlerlicense} />
                  
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Date in:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width:220, marginTop:17 }} size='small'  id="Adate" name="Adate" type='datetime-local'  variant="standard" onChange={myChangeHandleradate} />
                    </Col> 
                </Row>                                 
            </Col> 
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Model:</h5>                            
                    </Col>
                    <Col md={8}>
                    {/* <Autocomplete
                      id="idModel"
                      options={ dbModels.models}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label=" " variant="standard" onSelect={myChangeHandlermodel} />}
                    />  */}
                    <TextField  id="Model" name="Model" type='text' label=" " variant="standard" onChange={myChangeHandlermodel} />
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                     <br/>
                    <h5>VIN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 180 }} id="Bin" name="Bin" type='text' label=" " variant="standard" onChange={myChangeHandlerbin} />                    
                    </Col>
                </Row>  
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:17 }}>Date out:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" onChange={myChangeHandlerddate} />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'60px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'700px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'130px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'130px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'150px', borderBottomColor:'black'}}>Labor</th>
                <th style={{width:'150px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody style={{borderBottomColor:'white'}}>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes2}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab3} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab4} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab5} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab6} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>   
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>7</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des7" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes7} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri7" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri7} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua7" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua7} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab7" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab7} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri7 ?  parseInt(pri7) * parseInt(qua7) : 0) + (lab7 ? parseInt(lab7) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>8</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des8" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes8} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri8" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri8} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua8" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua8} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab8" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab8} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri8 ?  parseInt(pri8) * parseInt(qua8) : 0) + (lab8 ? parseInt(lab8) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>9</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des9" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes9} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri9" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri9} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua9" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua9} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab9" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab9} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri9 ?  parseInt(pri9) * parseInt(qua9) : 0) + (lab9 ? parseInt(lab9) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>    
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>10</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des10" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes10} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri10" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri10} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua10" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua10} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab10" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab10} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri10 ?  parseInt(pri10) * parseInt(qua10) : 0) + (lab10 ? parseInt(lab10) : 0 ) ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>                     
              <tr>
                <td style={{borderColor:'white', textAlign:'justify', paddingTop:20}} colSpan='3' rowSpan='2' >The customer authorized Auto Repair 3 Brothers to take the steps necessary to perform the repairs to the vehicle listed above as well as the repairs along listed in this invoice, including any necessary on-road vehicle testing.</td>
               
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                        (((pri1 ? parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 )  ) + 
                        ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 ) ) +
                        ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 ) ) + 
                        ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 ) ) +
                        ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 ) ) + 
                        ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ) )+ 
                        ((pri7 ?  parseInt(pri7) * parseInt(qua7) : 0) + (lab7 ? parseInt(lab7) : 0 ) )+ 
                        ((pri8 ?  parseInt(pri8) * parseInt(qua8) : 0) + (lab8 ? parseInt(lab8) : 0 ) )+ 
                        ((pri9 ?  parseInt(pri9) * parseInt(qua9) : 0) + (lab9 ? parseInt(lab9) : 0 ) )+ 
                        ((pri10 ?  parseInt(pri10) * parseInt(qua10) : 0) + (lab10 ? parseInt(lab10) : 0 ) )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td style={{borderBlockEnd:'black'}}></td>
                <td style={{borderBlockStart:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  onChange={myChangeHandleramo}/></strong></td>
              </tr> 
              <tr>
                <td  style={{borderColor:'white', textAlign:'justify'}} colSpan='3' rowSpan='2' >El cliente autorizó a Auto Repair 3 Brothers a tomar las medidas necesarias para llevar a cabo las reparaciones del vehiculo mencionado anteriormente, asi como las reparaciones junto con esta factura, incluida todas las pruebas necesarias en vehículos de carretera.</td>
                <td style={{borderBlockEnd:'black'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' >                
                  {/* <strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='number' label="" variant="standard" 
                  value = 
                  {
                   ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 ) ) + 
                     ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 ) ) +
                     ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 ) ) + 
                     ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 ) ) + 
                     ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 ) ) + 
                     ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ) ) + 
                     ((pri7 ?  parseInt(pri7) * parseInt(qua7) : 0) + (lab7 ? parseInt(lab7) : 0 ) ) + 
                     ((pri8 ?  parseInt(pri8) * parseInt(qua8) : 0) + (lab8 ? parseInt(lab8) : 0 ) ) + 
                     ((pri9 ?  parseInt(pri9) * parseInt(qua9) : 0) + (lab9 ? parseInt(lab9) : 0 ) ) + 
                     ((pri10 ?  parseInt(pri10) * parseInt(qua10) : 0) + (lab10 ? parseInt(lab10) : 0 ) )) - (amo ? parseInt(amo) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }
                  />
                  </strong>                   */}
                  <h6>
                    <strong>
                    {
                   ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 ) ) + 
                     ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 ) ) +
                     ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 ) ) + 
                     ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 ) ) + 
                     ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 ) ) + 
                     ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ) ) + 
                     ((pri7 ?  parseInt(pri7) * parseInt(qua7) : 0) + (lab7 ? parseInt(lab7) : 0 ) ) + 
                     ((pri8 ?  parseInt(pri8) * parseInt(qua8) : 0) + (lab8 ? parseInt(lab8) : 0 ) ) + 
                     ((pri9 ?  parseInt(pri9) * parseInt(qua9) : 0) + (lab9 ? parseInt(lab9) : 0 ) ) + 
                     ((pri10 ?  parseInt(pri10) * parseInt(qua10) : 0) + (lab10 ? parseInt(lab10) : 0 ) )) - (amo ? parseInt(amo) : 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }
                    </strong>
                  </h6>
                </td>
              </tr>  
              <tr>                                 
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
              </tr>
              <tr>
                <td style={{borderColor:'white'}}></td>
                 {/* <td style={{borderColor:'white'}}><Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" onSelect={myChangeHandlerpayment} />}
                  /> </td> */}
              </tr>
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
                <h5>Customer signature</h5>
            </Col>
            <Col md={6} className='text-center'>
                <h5>{todos[0] ? todos[0].businessname : ''}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>NOTE:</h6>
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                   onChange={myChangeHandlerrecommend}
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{todos[0] ? todos[0].bwebsite : ''}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row>
                <Col>
                   <Button                                
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}   
                         onClick={authentication}               
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
      </Container>
      </>
)}

export default InvoiceLast