import React, { useState } from 'react'
import { login, logout, selectUser } from '../../auth/users/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import Car from "../../models/Car"
export const SET_CARS = 'SET_CARS'
export const SET_CARS_UPDATE = 'SET_CARS_UPDATE'




export const fetchCars = (user) => {  
   
    return async dispatch => {   
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_ADD_CAR}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedCars = [];
             for(const key in resData) {
                loadedCars.push(new Car(
                  key, 
                  resData[key].Year, 
                  resData[key].Make, 
                  resData[key].Model,
                  resData[key].Vin,
                  resData[key].Color,
                  resData[key].Lpn,
                  resData[key].Active,
                  ))
             }
          dispatch({ type:SET_CARS, cars: loadedCars });
        } catch (err) {
          throw err;
        }
         
        } 
}

export const updateActive = (user, id, data) => {

  return async dispatch => {
      //any async code you want!
     await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_ADD_CAR}/${user}/data/${id}.json`, 
     {
       method:"PATCH",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
       alert("Successfully updated")
       )
     .catch(() => 
        alert("Error updating field")
     )

      // dispatch({ 
      //     type: UPDATE_PROFILE_DETAIL, 
      //     updateprofiledetail: data
      // })    
  }
}