import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import * as actionsCar from '../store/actions/car'
import firebase from "firebase";
import { db } from '../auth/firebase'
import Axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import {selectUser } from '../auth/users/userSlice'
import { Container, Row, Col, Spinner, Modal, Button } from 'react-bootstrap'
// import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { set } from 'date-fns';




const Reports = () => {

    const user = useSelector(selectUser)
    const dispatch = useDispatch();
    let history = useHistory();
    function historyhandleClick() {
      history.push("/account");
    }

    // const ultimo = useSelector(state => state.invoices.invoices)
    const [url, setUrl] = useState(null);
    const [todos, setTodos] = useState([]);
    const cars = useSelector(state => state.cars.cars)
    const clients = useSelector(state => state.clients.clients)

  
    const randon = Math.floor(Math.random() * (999999  - 202111 + 1) + 202111) 

    const [modalShow, setModalShow] = useState(false);
    const [modalShowactive, setModalShowactive] = useState(false);

    //updatecar
    const[activeupdate, setactiveupdate] = useState('')

    
  
    //Get profile picture from firebase
    const getProfileImage = () => {
      firebase
      .storage()
      .ref('users')
      .child(user.uid + "/logo-mac.png")
      .getDownloadURL()
      .then(imgUrl => {
        setUrl(imgUrl);
      });
    }
  
    useEffect(() => {
      setTimeout(() => {
        getProfileImage();
      }, 1000);
    }, [])
  
  
    
  
   // Get Todos
   const  getTodos = () => {
     db.collection(user.uid).onSnapshot(function(querySnapshot) {
       setTodos(
         querySnapshot.docs.map((doc) => ({
           id: doc.id,
           bcity: doc.data().bcity,
           bemail: doc.data().bemail,
           bstreet: doc.data().bstreet,
           businessname2: doc.data().businessname2,
           bphone: doc.data().bphone,
           bwebsite2: doc.data().bwebsite2
         }))
       );
     });
   }
  
   useEffect(() => {
    getTodos();    
  }, []) // blank to run only on first time

  const[year, setyear] = useState('')
  const[make, setmake] = useState('')
  const[model, setmodel] = useState('')
  const[vin, setvin] = useState('')
  const[color, setcolor] = useState('')
  const[lpn, setlpn] = useState('')

  function myChangeHandleryear(event) { setyear(event.target.value)}
  function myChangeHandlermake(event) { setmake(event.target.value)}
  function myChangeHandlermodel(event) { setmodel(event.target.value)}
  function myChangeHandlervin(event) { setvin(event.target.value)}
  function myChangeHandlercolor(event) { setcolor(event.target.value)}
  function myChangeHandlerlpn(event) { setlpn(event.target.value)}


  //activeupdate
  function myChangeHandleractiveupdate(event) { setactiveupdate(event.target.value)}



  const authentication = () => {          
 
    const data = {
            "Year": year,  
            "Make": make,   
            "Model": model,  
            "Vin": vin,    
            "Color": color,    
            "Lpn": lpn,    
            "Active": true    
         }       

   Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_ADD_CAR}/${user.uid}/data.json`, data)
     .then(r => {
       localStorage.setItem('token', r.data.token)
       window.location = "/"
     }).then(() => alert('Add Car has been succesfully saved'))
     .catch(e => {
       alert('error al iniciar sesion')
     } )

   }

   const[itemId, setitemId] = useState('')
   const[itemItem, setitemItem] = useState('Active')

   useEffect(() => {
     const dbactive = activeupdate != '' ? cars.find(x => x.Vin === activeupdate) : ''
    setitemId(dbactive != '' ? dbactive.id : 'NOT')
   },[])

   const dataActive = {
     "Active": false
   }

   const[clientslist, setcleantslist] = useState('')
   useEffect(() => {
    const dbclients = clients.filter(s => s.Active === true)
    setcleantslist(dbclients)
   },[])

   function submitactiveupdate() {
    const useee = user.uid
    const item = activeupdate != '' ?  cars.find(x => x.Vin === activeupdate) : 'Not'
    if(item != 'Not'){
      let idd = item.id
      dispatch(actionsCar.updateActive(useee, idd, dataActive))
      historyhandleClick()
    }   
   }


    return(
        <Container>          
            <Row className='mb-5'>
              <Col>
                     <div className='text-center' >
                               <Link to='/account'>
                                {
                                  url != null ?
                                  <img               
                                 style={{width: '230px', height:100}}
                                 src={url}
                                 alt='..'  /> :
                                 <Spinner animation="border" role="status">
                                   <span className="visually-hidden">Loading...</span>
                                 </Spinner>
                                }
                               </Link>
                           </div>
              </Col>
              <Col>               
                    <h2 className="text-center">Mac Auto Sales</h2>
                    <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                    {todos[0] ? todos[0].bcity : ''}<br/>(503) 681-0540</p>
              </Col>
             <Col></Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                <Button variant="primary" onClick={() => setModalShow(true)}>
                 Agregar un vehiculo
               </Button>
                 <Modal
                       show={modalShow}                       
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Agregar un vehiculo
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            <TextField fullWidth   id="Year" name="Year" type='number' label="Year" variant="standard" onChange={myChangeHandleryear} />
                            <TextField fullWidth   id="Make" name="Make" type='text' label="Make" variant="standard"  onChange={myChangeHandlermake} />
                            <TextField fullWidth   id="Model" name="Model" type='text' label="Model" variant="standard"  onChange={myChangeHandlermodel} />
                            <TextField fullWidth   id="Vin" name="Vin" type='text' label="Vin Number" variant="standard"  onChange={myChangeHandlervin} />
                            <TextField fullWidth   id="Color" name="Color" type='text' label="Color" variant="standard"  onChange={myChangeHandlercolor} />
                            <TextField fullWidth   id="License" name="License" type='text' label="License" variant="standard"  onChange={myChangeHandlerlpn} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                                
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                      <Button onClick={authentication} >Save</Button>
                         {/* <Button                                
                            type="button"
                             variant="outlined"
                             color="primary"
                             size="small"
                             className="primary"
                             startIcon={<SaveIcon />}   
                             onClick={authentication}               
                            >
                             Save
                        </Button> */}
                        <Button onClick={() => setModalShow(false)}>Close</Button>
                      </Modal.Footer>
                    </Modal>               
            </Col>
            <Col>
            <Button variant="primary" onClick={() => setModalShowactive(true)}>
                  Update a vehicle
               </Button>
                 <Modal
                       show={modalShowactive}                       
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                           Update a vehicle by Vin Number
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                              <Autocomplete
                                 id="idMake"
                                 options={cars.filter(x => x.Active === true)}
                                 getOptionLabel={(option) => option.Vin}                                 
                                 renderInput={(params) => <TextField {...params} id="Active" name="Active" type='text' label=" " variant="standard" onSelect={myChangeHandleractiveupdate} />}
                              /> 
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                                
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                      <Button onClick={submitactiveupdate} >Desactivar</Button>
                         {/* <Button                                
                            type="button"
                            variant='primary'
                             color="primary"
                             size="small"
                             className="primary"
                             startIcon={<SaveIcon />}   
                             onClick={submitactiveupdate}               
                            >
                             Desactivar
                        </Button> */}
                        <Button onClick={() => setModalShowactive(false)}>Close</Button>
                      </Modal.Footer>
                    </Modal>
            </Col>
          </Row>
            
            <Row>
                <h5>On sale</h5>
                <table className="table  table-sm">                  
                  <tbody>
                    <tr>
                      <th className='text-center' scope="row">Year</th>
                      <th className='text-center'>Make - Marca</th>
                      <th className='text-center'>Model - Modelo</th>
                      <th className='text-center'>VIN No - No de identificacion del vehiculo</th>
                      <th className='text-center' >Color</th>
                      <th className='text-center'>License plate No. - No de placa</th>
                    </tr>
                    {
                      cars.filter(s => s.Active === true).map(x => (
                        <tr key={x.id}>
                          <td>{x.Year}</td>
                          <td>{x.Make}</td>
                          <td>{x.Model}</td>
                          <td>{x.Vin}</td>
                          <td>{x.Color}</td>
                          <td>{x.Lpn}</td>                      
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </table>
        </Row>       
        <Row>
                <h5>Clients of list</h5>
                <table className="table  table-sm">    
                {
                      clients.filter(s => s.Active === true).map(x => (    
                  <tbody  key={x.id}>
                    <tr>
                      <th className='text-left' colSpan={6} scope="row">{`${x.First} ${x.Last} `}</th>
                    </tr> 
                    <tr>
                      <th className='text-center' scope="row">Phone</th>
                      <th className='text-center'>Phone work</th>
                      <th className='text-center'>License No</th>
                      <th className='text-center'>Address </th>
                      <th className='text-center' >Apt #</th>
                      <th className='text-center'>Zip code</th>
                    </tr> 
                    <tr>
                          <td>{x.Phone1}</td>
                          <td>{x.Phone2}</td>
                          <td>{x.License}</td>
                          <td>{x.Address}</td>
                          <td>{x.Apt}</td>
                          <td>{x.Zip}</td>                      
                    </tr> 
                    <tr>
                      <th className='text-left' colSpan={6} scope="row">Vehicle information</th>
                    </tr>
                    <tr>
                      <th className='text-center' scope="row">Year</th>
                      <th className='text-center'>Make - Marca</th>
                      <th className='text-center'>Model - Modelo</th>
                      <th className='text-center'>VIN No - No de identificacion del vehiculo</th>
                      <th className='text-center' >Color</th>
                      <th className='text-center'>License plate No. - No de placa</th>
                    </tr>                                         
                    <tr>
                          <td>{x.Year}</td>
                          <td>{x.Make}</td>
                          <td>{x.Model}</td>
                          <td>{x.Vin}</td>
                          <td>{x.Color}</td>
                          <td>{x.Lpn}</td>                      
                    </tr>  
                    <tr>
                      <th className='text-left' colSpan={6} scope="row">Schedule Payments</th>
                    </tr>
                    <tr>
                          <th className='text-center'>#</th>
                          <th className='text-center' colSpan={2} scope="row">Payment Schedule</th>
                          <th className='text-center' colSpan={2}>Amount - Cantidad</th>
                          <th className='text-center'>Actions</th>
                    </tr>

                          { 
                            x.Months &&
                          
                                x.Months.map(x => (
                            <tr key={x.id}>
                              <td className='text-center'>{x.id}</td>
                              <td colSpan={2}>{x.fecha1}</td>
                              <td colSpan={2}>{x.pago}</td>
                              <td></td>
                            </tr>
                          )) }
                  </tbody>
                   ))
                }
                </table>
        </Row>       
        </Container>
    )
}
export default Reports