import { SET_CARS } from "../actions/car";

const initialState = {
    cars: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CARS:
           return {
               ...state,
               cars: action.cars,
           }       
           default:
            break;
    }
    return state
}
