class Car {
    constructor(id, Year, Make, Model, Vin, Color, Lpn, Active ) {
    this.id = id;
    this.Year = Year;
    this.Make = Make;
    this.Model = Model;
    this.Vin = Vin;
    this.Color = Color;
    this.Lpn = Lpn;
    this.Active = Active
  
  }
    }
    
    export default Car;
    