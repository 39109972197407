import { SET_MAKES, SET_MODELS, SET_INVOICES } from "../actions/invoice";
import dbdata from '../../db/dbdata.json'
const initialState = {
    invoices: [],
    // invoice: [],
    makes: dbdata.brand,
    models: [],
    // invoiceSearch: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INVOICES:
           return {
               ...state,
               invoices: action.invoices,
           }
       case SET_MAKES:
           return {
               ...state,
               makes: dbdata.brand
           }
       case SET_MODELS:
        //    let data = []
           const modelos = dbdata.brand.filter(item => item.title === action.models)
            // data = [... data, modelos]
           return {
                ...state,
               models: modelos[0].models
           }
           default:
            break;
    }
    return state
}
