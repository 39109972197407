import React, { useState } from 'react'
import { login, logout, selectUser } from '../../auth/users/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import Client from "../../models/Client"
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_CLIENT_UPDATE = 'SET_CLIENT_UPDATE'




export const fetchClients = (user) => {  
   
    return async dispatch => {   
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_ADD_CLIENT}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedClients = [];
             for(const key in resData) {
                loadedClients.push(new Client(
                key, 
                resData[key].Last,
                resData[key].First,
                resData[key].Dbo,
                resData[key].License,
                resData[key].Address,
                resData[key].Apt,
                resData[key].City,
                resData[key].Zip,
                resData[key].Phone1,
                resData[key].Phone2,
                resData[key].Year,
                resData[key].Make,
                resData[key].Model,
                resData[key].Color,
                resData[key].Vin,
                resData[key].Lpn,
                resData[key].Price,
                resData[key].Soldat,
                resData[key].Datesold,
                resData[key].Warranty,
                resData[key].Warrantytext,
                resData[key].Refname1,
                resData[key].Refname2,
                resData[key].Refname3,
                resData[key].Refname4,
                resData[key].Refphone1,
                resData[key].Refphone2,
                resData[key].Refphone3,
                resData[key].Refphone4,
                resData[key].Down,
                resData[key].Datesign,
                resData[key].Months,
                resData[key].Rate,
                resData[key].Balance,
                resData[key].Monthlypay,
                resData[key].Active,
                ))
             }
          dispatch({ type:SET_CLIENTS, clients: loadedClients });
        } catch (err) {
          throw err;
        }
         
        } 
}

export const updateActiveclient = (user, id, data) => {

  return async dispatch => {
      //any async code you want!
     await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_ADD_CLIENT}/${user}/data/${id}.json`, 
     {
       method:"PATCH",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
       alert("Successfully updated")
       )
     .catch(() => 
        alert("Error updating field")
     )

      // dispatch({ 
      //     type: SET_CLIENT_UPDATE, 
      //     updateprofiledetail: data
      // })    
  }
}