import React, { useState } from 'react'
import { login, logout, selectUser } from '../../auth/users/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import Invoice from "../../models/Invoice"
export const SET_INVOICES = 'SET_INVOICES'
// export const SET_INVOICE = 'SET_INVOICE'
export const SET_MAKES = 'SET_MAKES'
export const SET_MODELS = 'SET_MODELS'
export const CREATE_INVOICE = 'CREATE_INVOICE'



export const createInvoice = (user, data) => {
 
  return async dispatch => {
      //any async code you want!
      // const user = useSelector(selectUser)
     await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_INVOICE}/${user}/data.json`, 
     {
       method:"POST",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
         alert("Successfully updated")
         )
       .catch(() => 
          alert("Error updating field")
       )

      dispatch({ 
          type: CREATE_INVOICE, 
          invoice: data
      })    
  }
}


export const fetchInvoices = (user) => {  
   
    return async dispatch => {   
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_INVOICE}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedInvoices = [];
             for(const key in resData) {
                loadedInvoices.push(new Invoice(
                  key, 
                  resData[key].Adate, 
                  resData[key].Balance, 
                  resData[key].Bin,
                  resData[key].Brand,
                  resData[key].Date,
                  resData[key].Ddate,
                  resData[key].Idinvoice,
                  resData[key].License,
                  resData[key].Lines_details,
                  resData[key].Make,
                  resData[key].Mileage,
                  resData[key].Model,
                  resData[key].Name,
                  resData[key].Payment,
                  resData[key].Phone,
                  resData[key].Recommend,
                  resData[key].Year,
                  resData[key].Amount,
                  ))
             }
          dispatch({ type:SET_INVOICES, invoices: loadedInvoices });
        } catch (err) {
          throw err;
        }
         
        } 
}


export const setMakes = items => {
    return {
        type: SET_MAKES, makes: items
    }
}
export const setModels = item => {
    return {
        type: SET_MODELS, models: item
    }
}