import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner  } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Link } from 'react-router-dom';
import './Macautosales.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
import dbMakes from "../db/dbMakes.json"
import dbModels from "../db/dbModels.json"

const Macautosales = () => {

  let f = new Date();
  // let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();
  let fecha1 = (f.getMonth() +1) + "/" + f.getDate() + "/" + f.getFullYear();
  let fecha2 = (f.getMonth() +2) + "/" + f.getDate() + "/" + f.getFullYear();
  let fecha3 = (f.getMonth() +3) + "/" + f.getDate() + "/" + f.getFullYear();
  let fecha4 = (f.getMonth() +4) + "/" + f.getDate() + "/" + f.getFullYear();
  let fecha5 = (f.getMonth() +5) + "/" + f.getDate() + "/" + f.getFullYear();
  let fecha6 = (f.getMonth() +6) + "/" + f.getDate() + "/" + f.getFullYear();

    const user = useSelector(selectUser)
  const ultimo = useSelector(state => state.invoices.invoices)
  const cars = useSelector(state => state.cars.cars)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const randon = Math.floor(Math.random() * (999999  - 202111 + 1) + 202111) 

  //Get profile picture from firebase
  const getProfileImage = () => {
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo-mac.png")
    .getDownloadURL()
    .then(imgUrl => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])


  

 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname2: doc.data().businessname2,
         bphone: doc.data().bphone,
         bwebsite2: doc.data().bwebsite2
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time


const[last, setlast] = useState('')
const[first, setfirst] = useState('')
const[dbo, setdbo] = useState('')
const[license, setlicense] = useState('')
const[address, setaddress] = useState('')
const[apt, setapt] = useState('')
const[city, setcity] = useState('')
const[zip, setzip] = useState('')
const[phone1, setphone1] = useState('')
const[phone2, setphone2] = useState('')

const[year, setyear] = useState('')
const[make, setmake] = useState('')
const[model, setmodel] = useState('')
const[color, setcolor] = useState('')
const[vin, setvin] = useState('')
const[lpn, setlpn] = useState('')
const[price, setprice] = useState(0)
const[soldat, setsoldat] = useState('')
const[datesold, setdatesold] = useState('')
const[warranty, setwarranty] = useState('')
const[warrantytext, setwarrantytext] = useState('')
const[refname1, setrefname1] = useState('')
const[refname2, setrefname2] = useState('')
const[refname3, setrefname3] = useState('')
const[refname4, setrefname4] = useState('')
const[refphone1, setrefphone1] = useState('')
const[refphone2, setrefphone2] = useState('')
const[refphone3, setrefphone3] = useState('')
const[refphone4, setrefphone4] = useState('')


const[down, setdown] = useState('')
const[balance, setbalance] = useState(0)
const[months, setmonths] = useState(0)
const[rate, setrate] = useState(0)
const[monthlypay, setmonthlypay] = useState(0)

const[datepay1, setdatepay1] = useState('')
const[datepay2, setdatepay2] = useState('')
const[datepay3, setdatepay3] = useState('')
const[datepay4, setdatepay4] = useState('')
const[datepay5, setdatepay5] = useState('')
const[datepay6, setdatepay6] = useState('')
const[payamount1, setpayamount1] = useState('')
const[payamount2, setpayamount2] = useState('')
const[payamount3, setpayamount3] = useState('')
const[payamount4, setpayamount4] = useState('')
const[payamount5, setpayamount5] = useState('')
const[payamount6, setpayamount6] = useState('')
const[datesign, setdatesign] = useState('')



function myChangeHandlerlast(event) { setlast(event.target.value)}
function myChangeHandlerfirst(event) { setfirst(event.target.value)}
function myChangeHandlerdbo(event) { setdbo(event.target.value)}
function myChangeHandlerlicense(event) { setlicense(event.target.value)}
function myChangeHandleraddress(event) { setaddress(event.target.value)}
function myChangeHandlerapt(event) { setapt(event.target.value)}
function myChangeHandlercity(event) { setcity(event.target.value)}
function myChangeHandlerzip(event) { setzip(event.target.value)}
function myChangeHandlerphone1(event) { setphone1(event.target.value)}
function myChangeHandlerphone2 (event) { setphone2 (event.target.value)}

function myChangeHandleryear (event) { setyear (event.target.value)}
function myChangeHandlermake (event) { setmake (event.target.value)}
function myChangeHandlermodel (event) { setmodel (event.target.value)}
function myChangeHandlercolor (event) { setcolor (event.target.value)}
function myChangeHandlervin (event) { setvin (event.target.value)}
function myChangeHandlerlpn (event) { setlpn (event.target.value)}
function myChangeHandlerprice (event) { setprice (event.target.value)}
function myChangeHandlersoldat (event) { setsoldat (event.target.value)}
function myChangeHandlerdatesold (event) { setdatesold (event.target.value)}
function myChangeHandlerwarranty (event) { setwarranty (event.target.value)}
function myChangeHandlerwarrantytext (event) { setwarrantytext (event.target.value)}
function myChangeHandlerrefname1 (event) { setrefname1 (event.target.value)}
function myChangeHandlerrefname2 (event) { setrefname2 (event.target.value)}
function myChangeHandlerrefname3 (event) { setrefname3 (event.target.value)}
function myChangeHandlerrefname4 (event) { setrefname4 (event.target.value)}
function myChangeHandlerrefphone1 (event) { setrefphone1 (event.target.value)}
function myChangeHandlerrefphone2 (event) { setrefphone2 (event.target.value)}
function myChangeHandlerrefphone3 (event) { setrefphone3 (event.target.value)}
function myChangeHandlerrefphone4 (event) { setrefphone4 (event.target.value)}

function myChangeHandlerdown (event) { setdown (event.target.value)}
function myChangeHandlermonths (event) { setmonths (event.target.value)}
function myChangeHandlerrate (event) { setrate (event.target.value)}
function myChangeHandlermonthlypay (event) { setmonthlypay (event.target.value)}

function myChangeHandlerbalance (event) { setbalance (event.target.value)}
function myChangeHandlerdatesign (event) { setdatesign (event.target.value)}



const moth1 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  }
]

const moth2 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]

const moth3 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]

const moth4 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]
const moth44 = [  
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]
const moth444 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]

const moth5 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  } 
]
const moth55 = [
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'5',
    'fecha1': fecha5,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }  
]

const moth555 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'5',
    'fecha1': fecha5,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }   
]

const moth6 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]
const moth66 = [
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'5',
    'fecha1': fecha5,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'6',
    'fecha1': fecha6,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]

const moth666 = [
  {
    'id':'1',
    'fecha1': fecha1,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) 
  },
  {
    'id':'2',
    'fecha1': fecha2,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'3',
    'fecha1': fecha3,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'4',
    'fecha1': fecha4,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'5',
    'fecha1': fecha5,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  },
  {
    'id':'6',
    'fecha1': fecha6,
    'pago': (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2)
  }
]




function print(){
  window.print()
}

function handlersubmit() {
  const data = {
    "Last": last,
    "First": first,
    "Dbo": dbo,
    "License": license,
    "Address": address,
    "Apt": apt,
    "City": city,
    "Zip": zip,
    "Phone1": phone1,
    "Phone2": phone2,
    "Year": year,
    "Make": make,
    "Model": model,
    "Color": color,
    "Vin": vin,
    "Lpn": lpn,
    "Price": price,
    "Soldat": soldat,
    "Datesold": datesold,
    "Warranty": warranty,
    "Warrantytext": warrantytext,
    "Refname1": refname1,
    "Refname2": refname2,
    "Refname3": refname3,
    "Refname4": refname4,
    "Refphone1": refphone1,
    "Refphone2": refphone2,
    "Refphone3": refphone3,
    "Refphone4": refphone4,
    "Down": down,
     "Datesign":datesign,
     "Months": months === '1' ? moth1 : months ===  '2' ? moth2 : months === '3' ? moth3 : months === '4' ? moth444  : months === '5' ? moth555 : months === '6' ? moth666 : '',
     "Rate":rate,
     "Balance":price - down,
     "Monthlypay": (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2),
     "Active": true 
  }
  Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_ADD_CLIENT}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Invoice has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )
}

    return(

    <Container >
        <Row>
          <Col>
                 <div className='text-center' >
                           <Link to='/account'>
                            {
                              url != null ?
                              <img               
                             style={{width: '230px', height:100}}
                             src={url}
                             alt='..'  /> :
                             <Spinner animation="border" role="status">
                               <span className="visually-hidden">Loading...</span>
                             </Spinner>
                            }
                           </Link>
                       </div>
          </Col>
          <Col>               
                <h2 className="text-center">Mac Auto Sales</h2>
                <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                {todos[0] ? todos[0].bcity : ''}<br/>(503) 681-0540</p>
          </Col>
          <Col></Col>
        </Row>
        <Row>
                <h5>Customer's information - Informacion del cliente</h5>
                <table className="table  table-sm">                  
                  <tbody>
                    <tr>
                      <th className='text-center' scope="row">Last Name - Apellido</th>
                      <th className='text-center'>First Name - Nombre</th>
                      <th className='text-center'>DBO - Fecha de nacimiento</th>
                      <th className='text-center'>License No. - No de Licencia</th>
                    </tr>
                    <tr>
                      <td><TextField fullWidth multiline  id="Last" name="Last" type='text' label="" variant="standard" onChange={myChangeHandlerlast} /></td>
                      <td><TextField fullWidth multiline  id="Name" name="Name" type='text' label="" variant="standard" onChange={myChangeHandlerfirst} /></td>
                      <td><TextField fullWidth   id="Dbo" name="Dbo" type='date' label="" variant="standard"  onChange={myChangeHandlerdbo} /></td>
                      <td><TextField fullWidth   id="License" name="License" type='text' label="" variant="standard"  onChange={myChangeHandlerlicense} /></td>
                    </tr>
                    <tr>
                      <th className='text-center' scope="row">Address - Direccion</th>
                      <th className='text-center'>Apt#</th>
                      <th className='text-center'>City - Ciudad</th>
                      <th className='text-center'>Zip code - Co Postal</th>
                    </tr>
                    <tr>
                      <td><TextField fullWidth multiline  id="Address" name="Address" type='text' label="" variant="standard" onChange={myChangeHandleraddress} /></td>
                      <td><TextField fullWidth  id="Apt" name="Apt" type='text' label="" variant="standard" onChange={myChangeHandlerapt} /></td>
                      <td><TextField fullWidth   id="City" name="City" type='text' label="" variant="standard" onChange={myChangeHandlercity} /></td>
                      <td><TextField fullWidth   id="Zip" name="Zip" type='number' label="" variant="standard" onChange={myChangeHandlerzip} /></td>
                    </tr>
                    <tr>
                      <th className='text-center' scope="row" >Primary phone No.</th>
                      <th scope="row" ><TextField fullWidth   id="Phone1" name="Phone1" type='number' label="" variant="standard" onChange={myChangeHandlerphone1} /></th>
                      <th className='text-center' >Secondary phone No.</th>
                      <td colSpan={2}><TextField fullWidth   id="Phone2" name="Phone2" type='number' label="" variant="standard" onChange={myChangeHandlerphone2} /></td>
                    </tr>
                  </tbody>
                </table>
        </Row>
        <Row>
                <h5>Vehicle Information - Informacion del vehiculo</h5>
                <table className="table  table-sm">                  
                  <tbody>
                    <tr>
                      <th className='text-center' scope="row">Year</th>
                      <th className='text-center'>Make - Marca</th>
                      <th className='text-center'>Model - Modelo</th>
                      <th className='text-center' colSpan={2}>VIN No - No de identificacion del vehiculo</th>
                    </tr>
                    <tr>
                      <td>
                            <Autocomplete
                            id="idYear"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Year}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label=" "  variant="standard" onSelect={myChangeHandleryear} />}
                          /> 
                      </td>
                      {/* <td><TextField fullWidth   id="Year" name="Year" type='number' label="" variant="standard" /></td> */}
                      <td> <Autocomplete
                            id="idMake"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Make}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label=" "  variant="standard" onSelect={myChangeHandlermake} />}
                          /> </td>
                      <td> <Autocomplete
                            id="idModel"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Model}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label=" "  variant="standard" onSelect={myChangeHandlermodel}  />}
                          /> </td>
                      <td colSpan={2}> <Autocomplete
                            id="idVin"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Vin}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Vin" name="Vin" type='text' label=" "  variant="standard" onSelect={myChangeHandlervin} />}
                          /> </td>
                    </tr>
                    <tr>
                      <th className='text-center' scope="row">Color</th>
                      <th className='text-center'>License plate No. - No de placa</th>
                      <th className='text-center'>Price - Precio</th>
                      <th className='text-center'>Sold At - Vendido en</th>
                      <th className='text-center'>Date Sold - Fecha de venta</th>
                    </tr>
                    <tr>
                      <td > <Autocomplete
                            id="idColor"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Color}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Color" name="Color" type='text' label=" "  variant="standard" onSelect={myChangeHandlercolor} />}
                          /> 
                      </td>
                           <td> <Autocomplete
                            id="idLpn"
                            options={cars.filter(x => x.Active === true)}
                            getOptionLabel={(option) => option.Lpn}
                            // style={{ width: 160 }}
                            renderInput={(params) => <TextField {...params} id="Lpn" name="Lpn" type='text' label=" "  variant="standard"  onSelect={myChangeHandlerlpn} />}
                          /> </td>
                      <td><TextField fullWidth   id="Price" name="Price" type='number' label="" variant="standard"  onChange={myChangeHandlerprice} /></td>
                      <td><TextField fullWidth multiline  id="Sold" name="Sold" type='text' label="" variant="standard"  onChange={myChangeHandlersoldat} /></td>
                      <td><TextField fullWidth  id="Datesold" name="Datesold" type='date' label="" variant="standard"  onChange={myChangeHandlerdatesold} /></td>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={5}>Warranty - Garantia</th>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={5}>NO WARRANTY, SOLD AS IS. If warranty is applied spokenly it shall be written as well and signed by the seller. </th>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={5}>SIN GARANTIA, SE VENDE COMO ESTA. Si se aplica la garantia verbalmente debera escribirse asi y firmarse por el vendedor. </th>
                    </tr>
                    <tr>
                        <th colSpan={5}>Was warranty applied? - Se aplico garantia?</th>
                    </tr>
                    <tr>
                      <td>
                        <select className="warranty" id="warranty"  onChange={myChangeHandlerwarranty} >
                           <option defaultValue=''>Choose...</option>
                           <option value="YES (WARRANTY)">YES (WARRANTY)</option>
                           <option value="NO (WARRANTY)" >NO (WARRANTY)</option>
                         </select>
                      </td>
                      <td colSpan={4}><TextField fullWidth  id="Warrantytext" name="Warrantytext" type='text' label="" variant="standard" onChange={myChangeHandlerwarrantytext} /></td>
                    </tr>
                  </tbody>
                </table>
        </Row>
        <Row>
                <h5>References - Referencias</h5>
                <table className="table  table-sm">                  
                  <tbody>
                    <tr>
                      <th className='text-center'>1</th>
                      <th className='text-center' scope="row">Full name</th>                      
                      <td><TextField fullWidth   id="Name1" name="Name1" type='text' label="" variant="standard" onChange={myChangeHandlerrefname1} /></td>
                      <th className='text-center'>Phone No. </th>                      
                      <td><TextField fullWidth   id="Phone1" name="Phone1" type='number' label="" variant="standard"  onChange={myChangeHandlerrefphone1} /></td>
                      <th className='text-center'>3</th>
                      <th className='text-center' scope="row">Full name</th>                      
                      <td><TextField fullWidth   id="Name3" name="Name3" type='text' label="" variant="standard"  onChange={myChangeHandlerrefname3} /></td>
                      <th className='text-center'>Phone No.</th>                      
                      <td><TextField fullWidth   id="Phone3" name="Phone3" type='number' label="" variant="standard" onChange={myChangeHandlerrefphone3} /></td>
                    </tr>
                    <tr>
                      <th className='text-center'>2</th>
                      <th className='text-center' scope="row">Full name</th>                      
                      <td><TextField fullWidth   id="Name2" name="Name2" type='text' label="" variant="standard" onChange={myChangeHandlerrefname2}/></td>
                      <th className='text-center'>Phone No. </th>                      
                      <td><TextField fullWidth   id="Phone2" name="Phone2" type='number' label="" variant="standard" onChange={myChangeHandlerrefphone2}/></td>
                      <th className='text-center'>4</th>
                      <th className='text-center' scope="row">Full name</th>                      
                      <td><TextField fullWidth   id="Name4" name="Name4" type='text' label="" variant="standard" onChange={myChangeHandlerrefname4} /></td>
                      <th className='text-center'>Phone No.</th>                      
                      <td><TextField fullWidth   id="Phone4" name="Phone4" type='number' label="" variant="standard" onChange={myChangeHandlerrefphone4} /></td>
                    </tr>                    
                  </tbody>
                </table>
        </Row>
        <Row>
                <h5>Payment Arrangement - Acuerdo de pago</h5>
                <table className="table  table-sm">                  
                  <tbody> 
                    <tr>
                      <th className='text-left' scope="row">Total cost of car</th>
                      <th className='text-left'>{`$ ${price}`}</th>
                      <th className='text-left'>Down Payment</th>
                      <th className='text-left'><TextField fullWidth id="Down" name="Down" type='number' label="" variant="standard" onChange={myChangeHandlerdown} /></th>
                      <th className='text-left'  scope="row">Balance due</th>
                      <th className='text-left' >
                        {(price - down)}
                      </th>
                    </tr> 
                    <tr>                     
                      <th className='text-left' scope="row">Number of months</th>
                      <th className='text-left'>
                        <select className="custom-select" id="inputGroupSelect033" onChange={myChangeHandlermonths}>
                           <option  defaultValue={months}>Choose...</option>
                           <option value="1">1 Month</option>
                           <option value="2">2 Months</option>
                           <option value="3">3 Months</option>
                           <option value="4">4 Months</option>
                           <option value="5">5 Months</option>
                           <option value="6">6 Months</option>
                         </select>
                      </th>
                      <th className='text-left' scope="row">Month interest rate</th>
                      <th className='text-left'>
                        <select className="custom-select" id="inputGroupSelect02" onChange={myChangeHandlerrate}>
                           <option defaultValue={rate}>Choose...</option>
                           <option value="100">$ 100.00</option>
                           <option value="90">$ 90.00</option>
                           <option value="80">$ 80.00</option>
                           <option value="0">$ 0.00</option>
                         </select>
                      </th>
                      <th className='text-left' scope="row">Monthly payment</th>
                      <th className='text-left'>
                        { (((parseInt(price) - parseInt(down)) / (parseInt(months))) + parseInt(rate)).toFixed(2) }
                      </th>
                    </tr>                       
                  </tbody>
                </table>
                {
                    months === '1' &&
                    <table className="table  table-sm">                  
                      <tbody>           
                        <tr>
                          <th className='text-center'>#</th>
                          <th className='text-center' scope="row">Payment Schedule</th>
                          <th className='text-center'>Amount - Cantidad</th>
                        </tr>

                          { moth1.map(x => (
                            <tr key={x.id}>
                              <td className='text-center'>{x.id}</td>
                              <td>{x.fecha1}</td>
                              <td>{x.pago}</td>
                            </tr>
                          )) }

                      </tbody>
                    </table>
                }
                {
                    months === '2' &&
                    <table className="table  table-sm">                  
                      <tbody>           
                        <tr>
                          <th className='text-center'>#</th>
                          <th className='text-center' scope="row">Payment Schedule</th>
                          <th className='text-center'>Amount - Cantidad</th>
                        </tr>

                          { moth2.map(x => (
                            <tr key={x.id}>
                              <td className='text-center'>{x.id}</td>
                              <td>{x.fecha1}</td>
                              <td>{x.pago}</td>
                            </tr>
                          )) }

                      </tbody>
                    </table>
                }
                {
                    months === '3' &&
                    <table className="table  table-sm">                  
                      <tbody>           
                        <tr>
                          <th className='text-center'>#</th>
                          <th className='text-center' scope="row">Payment Schedule</th>
                          <th className='text-center'>Amount - Cantidad</th>
                        </tr>

                          { moth3.map(x => (
                            <tr key={x.id}>
                              <td className='text-center'>{x.id}</td>
                              <td>{x.fecha1}</td>
                              <td>{x.pago}</td>
                            </tr>
                          )) }

                      </tbody>
                    </table>
                }
                {
                    months === '4' &&
                      <Row>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth4.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        

                          </tbody>
                        </table>
                        </Col>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth44.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        
                              
                          </tbody>
                        </table>
                        </Col>
                      </Row>                   
                }
                {
                    months === '5' &&
                      <Row>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth5.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        

                          </tbody>
                        </table>
                        </Col>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth55.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        
                              
                          </tbody>
                        </table>
                        </Col>
                      </Row>                   
                }
                {
                    months === '6' &&
                      <Row>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth6.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        

                          </tbody>
                        </table>
                        </Col>
                        <Col>
                        <table className="table  table-sm">                  
                          <tbody>           
                            <tr>
                              <th className='text-center'>#</th>
                              <th className='text-center' scope="row">Payment Schedule</th>
                              <th className='text-center'>Amount - Cantidad</th>
                            </tr>

                              { moth66.map(x => (
                                <tr key={x.id}>
                                  <td className='text-center'>{x.id}</td>
                                  <td>{x.fecha1}</td>
                                  <td>{x.pago}</td>                              
                                </tr>
                              ))                           
                              }                        
                              
                          </tbody>
                        </table>
                        </Col>
                      </Row>                   
                }
                
        </Row>
        <Row>
                <h5>Agreement - Acuerdo</h5>
                <table className="table  table-sm">                  
                  <tbody>                   
                    <tr>
                      <td scope="row" colSpan={2}>I agree that payments be made on time. If two consecutive payments are not made, the vehicle will be seized automatically. If Iwant to claim the vehicle after it has been seized I will then have to pay for the vehicle in full and pay for the towing. </td>
                    </tr>
                    <tr>
                      <td scope="row" colSpan={2}>Estoy de acuerdo en que los pagos se realizaran a tiempo. Si dos pagos consecutivos no se hacen, el vehiculo quedara automaticamente reposeido. Si quiero reclamar el vehiculo despues de haber sido reposeido tendre que pagar por el vehiculo en su totalidad y pagar por la grua.</td>
                    </tr>
                    <tr>
                      <th>Signature - Firma:</th>
                      <td><TextField fullWidth   id="Firmadate" name="Firmadate" type='date' label="" variant="standard" onChange={myChangeHandlerdatesign} /></td>
                    </tr>
                  </tbody>
                </table>
        </Row>
        <Row>
                <Col>
                   <Button                                
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}   
                         onClick={handlersubmit}               
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
    </Container>
    )
}
export default Macautosales