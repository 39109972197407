import Estimate from "../../models/Estimate"
export const SET_ESTIMATES = 'SET_ESTIMATES'

export const fetchEstimates = (user) => {
    return async dispatch => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_ESTIMATE}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedEstimates = [];
             for(const key in resData) {
                loadedEstimates.push(new Estimate(
                     key, 
                     resData[key].Idinvoice,
                     resData[key].Name,
                     resData[key].Phone,
                     resData[key].Address,
                     resData[key].Location,
                     resData[key].Date, 
                     resData[key].Brand,
                     resData[key].Make,
                     resData[key].Model,
                     resData[key].Year,
                     resData[key].License,
                     resData[key].Bin,
                     resData[key].Mileage,
                     resData[key].Adate ,
                     resData[key].Ddate ,
                     resData[key].Lines_details 
                     ))
             }
          dispatch({ type:SET_ESTIMATES, estimates: loadedEstimates });
        } catch (err) {
          throw err;
        }
         
        } 
}

