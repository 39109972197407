import { SET_CLIENTS } from "../actions/client";

const initialState = {
    clients: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENTS:
           return {
               ...state,
               clients: action.clients,
           }       
           default:
            break;
    }
    return state
}
